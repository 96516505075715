<!--Loading Overlay-->

<div class="loadingOverlay" *ngIf="loadingOverlay">
  <img class="loadingIconClass" src="../../../assets/images/doubleRingLoading.gif" alt="">
</div>


<div *ngIf="!eodHistorydisplay">
  <div class="sectionCardTop">
    <div class="breadcrumbPane">
      <ctrm-breadcrumb [collapsabel]="false"
                       [items]="breadCrumbService.getBreadCrumb()"
                       [showIcon]="false"></ctrm-breadcrumb>
    </div>
    <div class="actionPane">
      <div class="screenDetails">
        <i *ngIf="secondLevel" class="fas fa-chevron-left backIcon" (click)="changeRouteToBack()"></i>
        <img *ngIf="!secondLevel" [src]="'../../../assets/images/svg/tradePlanning.svg'"/>
        <p *ngIf="secondLevel">{{secondLevelTitle}}</p>
        <p *ngIf="!secondLevel">EOD Dashboard</p>
      </div>
      <div *ngIf="!secondLevel" class="autocomplete-box">
        <input type="text" placeholder="Search" (input)="getFilterValue($event)">
        <mat-icon style="color:#cbcbcb">search</mat-icon>
        <!--      <mat-icon *ngIf="isFocused" class="closable" (click)="clearAndClose()">clear</mat-icon>-->
      </div>
      <div *ngIf="!secondLevel" class="rightInfo">
        Total {{eodData.length}} Jobs
      </div>
    </div>
  </div>

  <!--Display New EOD Report and Run buttons-->
  <div *ngIf="!secondLevel" class="buttonsContainer">

    <div class="leftBtns">
      <div class="btnDiv btnOrange" (click)="loadData()">
        <i class="fas fa-plus btnIcon"></i>
        <p>New EOD Report</p>
      </div>
      <div
        [ngClass]="selectedJobs.length > 0 && selectedJobs.length < 2 ?['btnDiv','btnOrange']:['btnDiv','btnDisabled']"
        (click)="showDialogBox()">
        <i class="far fa-play-circle btnIcon"></i>
        <p>Run EOD Report</p>
      </div>
    </div>
    <div class="rightBtns">
      <div class="btnDiv btnPurple">
        <p>View EOD Templates</p>
      </div>
    </div>

  </div>
  <!--dialog box-->

  <p-dialog [(visible)]="eodDisplay"
            [closeOnEscape]="true"
            [draggable]="false"
            (onHide)="onFormCancel()"
            [maximizable]="false" [modal]="true" [showHeader]="false"
            [style]="{zIndex:1000,width: '30%',height: 'auto',minHeight:'40%', padding:'0px', background:'#ffffff', position:'left'}">
    <div style="display: flex; padding-left: 3%; padding-top: 1%;" class="formSectionTitle">
      <i class="far fa-play-circle btnIconDialog"></i>
      <p style="font-size: 20px; font-weight: bold; color: #150d4e">Run EOD</p>
      <i class="material-icons closableDialog" pTooltip="Close" tooltipZIndex="436" tooltipPosition="left"
         (click)="!isRunning?closeEod():''">clear</i>
    </div>
    <div class="sectionBoxMain">
      <p class="dialogHeading">Choose the Date to run the EOD Report</p>
      <div>
        <p style="padding-left: 10%; color: #150d4e; font-size: 15px;
    font-weight: bold;">Run for Value Date:</p>
        <div style="display: flex; margin-bottom: 25px;">
          <input [(ngModel)]="valueDate"
                 [ngModelOptions]="{timezone:'UTC'}"
                 bsDatepicker
                 class="datepicker" placeholder="Select Date" type="text">

          <i class="pi pi-calendar" style="font-size: 27px; padding-top: 3%; padding-left: 5px"></i>
        </div>
      </div>
    </div>
    <p-footer>
      <div style="border-top: 1px solid #ededed;">
        <button type="button" [hidden]="isRunning" pButton label="Start EOD" class="btnDiv btnOrange" (click)="runEOD()"
                style="float: right;padding-top: 2%; margin-top: 12px"></button>
        <div [hidden]="!isRunning" style="float: right;padding-top: 2%; margin-top: 12px" class="loadingbutton">
          <div class="spinner-border" role="status"></div>
          <p>Running Eod Job</p>
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <!--Side Panel to create the EOD-->

  <!--Toaster Message-->
  <p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
           appendTo="body">
  </p-toast>


  <!--EOD Job Details-->
  <div *ngIf="!secondLevel" class="planningPanel">
    <div class="toolbar">
      <div>
        <ctrm-button (onClick)="onclickedit($event)" [disabled]="selectedJobs.length > 0 && selectedJobs.length < 2 ? false : true" icon="fas fa-edit" label="Edit"></ctrm-button>
        <ctrm-button (onClick)="planRefresh()"  icon="fas fa-redo" label="Refresh"></ctrm-button>
        <!--      <div class="float-right">-->

        <!--        <ctrm-button [disabled]="selectedJobs.length === 0 || selectedJobs.length > 1" icon="fas fa-trash" [label]="'Delete'" ></ctrm-button>-->
        <!--        <ctrm-button [disabled]="selectedJobs.length === 0 || selectedJobs.length > 1" icon="fas fa-file-export" label="Export"></ctrm-button>-->
        <!--        <ctrm-button [disabled]="selectedJobs.length === 0 || selectedJobs.length > 1" icon="fas fa-print" label="Print"></ctrm-button>-->
        <!--        <ctrm-button [disabled]="selectedJobs.length === 0 || selectedJobs.length > 1" icon="fas fa-ellipsis-v" label="Log"></ctrm-button>-->
        <!--      </div>-->
      </div>
    </div>

    <!--  EOD Job -->
    <div class="tableClass">
      <p-table #jobTable inViewport (inViewportAction)="calculateJobTableHeight()"
               [style]="{width: '100%', maxHeight: jobTableHeight}"
               [style.minHeight]="eodData.length>0?jobTableHeight:'550px'"
               [(selection)]="selectedJobs"
               selectionMode="multiple"
               [autoLayout]="true"
               [columns]="eodCols" [value]="eodData" dataKey="eodTemplateName"
               class="style-4 table-responsive">

        <ng-template let-columns pTemplate="header">
          <tr class="stk">
            <th class="stk" style="width: 3em">
              <p style="background-color: #D9DBDE; padding-top: 11px; text-align: center;">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </p>
            </th>
            <th class="stk" *ngFor="let col of columns; index as i">
              <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
                <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">
          <tr>
            <td class="customRow" style="width:3em;">
              <p class="plannedGridChkBox">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </p>
            </td>

            <td *ngFor="let col of columns; index as i" class="customRow">
              <p *ngIf="!col.primary && !col.date && !col.curr && col.field !== 'EODJobId' && col.field !== 'lastRunStatus'&& col.field !== 'viewHistory'" class="plannedGrid">
                {{commonService.getRowValue(rowData, col)}}
              </p>
              <p *ngIf="col.date" class="plannedGrid">
                {{getDate(rowData[col.field])}}
              </p>
              <p *ngIf="col.curr" class="plannedGrid">
                {{getCurrency(rowData,col)}}
              </p>
              <p *ngIf="col.primary" (click)="onPrimaryClick(rowData)" class="plannedGrid primaryClass">
                {{commonService.getRowValue(rowData, col)}}
              </p>

              <div *ngIf="col.field === 'EODJobId'" style="display: flex">

                <!--              <p class="plannedGrid" style="padding-top: 2px">{{rowData[col.field]}}</p>-->
              </div>
              <div *ngIf="col.field === 'lastRunStatus'" style="display:flex;">
                <p class="statusLabel" [ngClass]="getClassForStatus(getStatusValue(rowData,col),rowData['eodTemplateName'])">{{getStatusValue(rowData,col)}}</p>
              </div>
              <div *ngIf="col.field === 'viewHistory'" style="display:flex;">
                <p style="cursor:pointer; margin: 0;width: auto"
                   (click)="showHistory(rowData);">
                  <i class="fa fa-ellipsis-h" pTooltip="View History" tooltipZIndex="436" tooltipPosition="bottom"></i>
                </p>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td [colSpan]="eodCols.length + 3">
              <div *ngIf="eodData.length === undefined || eodData.length === null || eodData.length === 0"
                   class="placeHolder">
                <div class="noPlans">No EOD Record</div>
                <div>
                  <button (click)="loadData()" class="planScreenBtns">Create EOD Job</button>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!--      <div *ngIf="eodData.length == 0 || eodData.length == null" style="text-align: center;">-->
      <!--        <div class="noPlans">No EOD Record</div>-->
      <!--        <div>-->
      <!--          <button (click)="onCreateEOD()" class="planScreenBtns">Create EOD Job</button>-->
      <!--        </div>-->
      <!--      </div>-->

    </div>
  </div>


  <!--secondLevel Sections-->
  <div *ngIf="secondLevel">
    <div class="eodDetailsContainer">
      <div class="detailWrapper">
        <div class="detailFieldWrapper">
          <div class="detailField">
            <p class="fieldTitle">Value Date</p>
            <p class="detailData">{{getDate(jobDetails['valueDate'])}}</p>
          </div>
          <div class="detailField">
            <p class="fieldTitle">Company</p>
            <p class="detailData">{{jobDetails['company']}}</p>
          </div>
<!--          <div class="detailField">-->
<!--            <p class="fieldTitle">Profit Center</p>-->
<!--            <div class="pcWrapper">-->
<!--              <p *ngFor="let pc of onlyUnique(jobDetails['profitcenter'])" class="pallet">{{pc}}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="detailField">-->
<!--            <p class="fieldTitle">Commodity</p>-->
<!--            <div class="pcWrapper">-->
<!--              <p *ngFor="let commodity of onlyUnique(jobDetails['commodity'])" class="pallet" style="background-color: #F0F0F0">{{commodity}}</p>-->
<!--            </div>-->
<!--          </div>-->
          <div class="toolbarBtnHolder">
            <div class="toolbarBtn published" *ngIf="!jobDetails['published']" [ngClass]="{'publishDisabled': isPublishedTriggered}"  (click)="publishEOD()">
              <i class="fas fa-upload toolbarBtnIcon" style="color: white"></i>
              <p class="btnTitle" style="color: white">Publish</p>
            </div>
            <div class="toolbarBtn">
              <i class="fas fa-history toolbarBtnIcon"></i>
              <p class="btnTitle">History</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="graphSection row"> <!--Collapsable-->
      <div style="display: flex">
        <i [ngClass]="collapsed?'fas fa-caret-right':'fas fa-caret-down'" style="cursor: pointer; padding-right: 10px;font-size: 20px" (click)="graphCollapse()"></i><p class="midSecTitle" *ngIf="collapsed">Graph View</p>
        <div class="midSecNav" *ngIf="!collapsed">
          <p (click)="changeGraphView('position')" [ngClass]="selectedGraphMode === 'position'?'navOptionSelected':'navOption'">Position</p>
          <p (click)="changeGraphView('m2m')" [ngClass]="selectedGraphMode === 'm2m'?'navOptionSelected':'navOption'">PnL</p>
        </div>
      </div>
      <div style="display: flex; width: 100%"  *ngIf="!collapsed && !posGraph">
        <div class="col-md-4">
          <div class="graphWrapper">
            <div class="graphHeadingWrapper">
              <p class="graphHeading">Profit Center</p>
              <div class="legendWrapper" style="display: flex">
                <span class="dot1"></span>
                <p class="legendText">MTM</p>
                <span class="dot2"></span>
                <p class="legendText">Realized</p>
              </div>
            </div>
            <div>
              <canvas baseChart
                      [ngClass]="'barChartStyle'"
                      [datasets]="barChartPC"
                      [labels]="chartLabelPC"
                      [options]="barChartOptions"
                      [colors]="barChartColors"
                      [legend]="false"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="graphWrapper">
            <div class="graphHeadingWrapper">
              <p class="graphHeading">Commodity</p>
              <div class="legendWrapper" style="display: flex">
                <span class="dot1"></span>
                <p class="legendText">MTM</p>
                <span class="dot2"></span>
                <p class="legendText">Realized</p>
              </div>
            </div>
            <div>
              <canvas baseChart
                      [ngClass]="'barChartStyle'"
                      [datasets]="barChartComm"
                      [labels]="chartLabelComm"
                      [options]="barChartOptions"
                      [colors]="barChartColors"
                      [legend]="false"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="graphWrapper">
            <div class="graphHeadingWrapper">
              <p class="graphHeading">Counterparty</p>
              <div class="legendWrapper" style="display: flex">
                <span class="dot1"></span>
                <p class="legendText">MTM</p>
                <span class="dot2"></span>
                <p class="legendText">Realized</p>
              </div>
            </div>
            <div>
              <canvas baseChart
                      [ngClass]="'barChartStyle'"
                      [datasets]="barChartCP"
                      [labels]="chartLabelCP"
                      [options]="barChartOptions"
                      [colors]="barChartColors"
                      [legend]="false"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; width: 100%"  *ngIf="!collapsed && posGraph">
        <div class="col-md-4">
          <div class="graphWrapper">
            <div class="graphHeadingWrapper">
              <p class="graphHeading">Profit Center</p>
              <div style="display: flex" class="legendWrapper">
                <span class="dot4"></span>
                <p class="legendText">Buy Open Pos</p>
                <span class="dot3"></span>
                <p class="legendText">Sell Open Pos</p>
                <span class="dot2"></span>
                <p class="legendText">Close Pos</p>
              </div>
            </div>
            <div>
              <canvas baseChart
                      [ngClass]="'barChartStyle'"
                      [datasets]="barChartPosPC"
                      [labels]="chartLabelPC"
                      [options]="barChartOptions"
                      [colors]="barChartColors"
                      [legend]="false"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="graphWrapper">
            <div class="graphHeadingWrapper">
              <p class="graphHeading">Commodity</p>
              <div style="display: flex" class="legendWrapper">
                <span class="dot4"></span>
                <p class="legendText">Buy Open Pos</p>
                <span class="dot3"></span>
                <p class="legendText">Sell Open Pos</p>
                <span class="dot2"></span>
                <p class="legendText">Close Pos</p>
              </div>
            </div>
            <div>
              <canvas baseChart
                      [ngClass]="'barChartStyle'"
                      [datasets]="barChartPosComm"
                      [labels]="chartLabelComm"
                      [options]="barChartOptions"
                      [colors]="barChartColors"
                      [legend]="false"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="graphWrapper">
            <div class="graphHeadingWrapper">
              <p class="graphHeading">Counterparty</p>
              <div style="display: flex" class="legendWrapper">
                <span class="dot4"></span>
                <p class="legendText">Buy Open Pos</p>
                <span class="dot3"></span>
                <p class="legendText">Sell Open Pos</p>
                <span class="dot2"></span>
                <p class="legendText">Close Pos</p>
              </div>
            </div>
            <div>
              <canvas baseChart
                      [ngClass]="'barChartStyle'"
                      [datasets]="barChartPosCP"
                      [labels]="chartLabelCP"
                      [options]="barChartOptions"
                      [colors]="barChartColors"
                      [legend]="false"
                      [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="toggleBtnWrapper">
      <div (click)="downloadEodRawData()" class="toggleBtn">Download Raw Data</div>
    </div>

  </div>


  <div *ngIf="secondLevel" class="secLvlGridWrapper" #pivotDiv>
    <div *ngIf="eodDetailData.length > 0">
      <p-table #pivotTable inViewport (inViewportAction)="calculatePivotHeight()"
               [style]="{width: '100%', maxHeight: pivotTableHeight}"
               [style.minHeight]="eodData.length>0?pivotTableHeight:'400px'"
               [columns]="eodDetailCols" [value]="eodDetailData" dataKey="profitcenter"
               class="style-4 table-responsive">
        <ng-template let-columns pTemplate="header">
          <tr class="sticky-top">

            <th class="stk" style="width: 3em">
              <p
                style="background-color: #D9DBDE; padding: 5px;padding-top: 15px; padding-bottom: 10px; text-align: center;">
              </p>
            </th>
            <th class="stk" *ngFor="let col of columns;index as i">
              <div style="background-color: #D9DBDE; padding: 5px;padding-top: 13px; padding-bottom: 14px">
                <p [ngClass]="getClassForHeading(i)">{{col.header}}</p>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-expanded="expanded" let-ri="rowIndex" let-rowData pTemplate="body">

          <tr>
            <td class="customRow" style="width: 3em; text-align: center;">
              <p style="margin-left: 5px">
                <a [pRowToggler]="rowData" href="#">
                                  <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="expandRow(rowData, ri)" class="togglerIcon"></i>
                </a>
              </p>
            </td>
            <td *ngFor="let col of columns" class="customRow">
              <p *ngIf="!col.curr && !col.qty" class="plannedGrid">{{getRowDataValue(rowData,col)}}</p>

              <p *ngIf="col.curr" class="plannedGrid">
                {{getCurrency(rowData,col)}}
              </p>
              <p *ngIf="col.qty" class="plannedGrid">
                {{getUom(rowData,col)}}
              </p>
            </td>
          </tr>

        </ng-template>
        <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
          <tr>
            <td [colSpan]="columns.length + 1">
              <div>
                <app-expand-grid [value]="rowData['groupedData']"></app-expand-grid>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div *ngIf="eodDetailData.length === 0">
      <p class="noDataClass">No Data Available</p>
    </div>
  </div>



</div>

<app-eod-history *ngIf="eodHistorydisplay" [refresh]="refresh" (onRefreshComplete)="onRefreshComplete()" (eodhistoryClose)="closeEodHistory($event)" [data]="eodHistoryData" (onInProgressEventFound)="onLiveEventFound($event)"
                 [jobName]="eodTemplateName"></app-eod-history>
<eod-reports-sidebar *ngIf="secondLevel" [eodJobName]="secondLevelTitle" [isCurrentPublished]="isPublished" [eodRunDate]="selectedJobforDashboard['lastRunValueDate']" (onClickTemplate)="onClickTemplate($event)"></eod-reports-sidebar>

<app-input-form *ngIf="display"
                [saveButtonLabel]="saveButtonLabel"
                [header]="'New EOD job'"
                [extraButtons]="extrabuttons"
                [formGroup]="eodFormGroup"
                [validationSections]="validationSections"
                [visible]="display"
                (onSave)="saveEodAndRun($event,false)"
                (onClickExtraButton)="saveEodAndRun($event,true)"
                [formContent]="formcontent" (onClose)="closeInputForm()"></app-input-form>
<ng-template #formcontent>
    <form [formGroup]="eodFormGroup">
      <div class="mainbox">
        <div class="sectionBoxMain">
          <div class="formSectionTitle">
            <div class="headerTitle"> Choose Criteria</div>
          </div>
          <div class="row rowClass">
            <div class="col-md-4 controlGap contentbox">
              <div class="fieldBox">
                <label class="label ng-star-inserted">EOD Job Name</label>
                <input id="input" type="text"
                       (input)="commonService.updateInputValidation($event,includedCharacters,true)"
                       formControlName="eodTemplateName"
                       class="form-control form-control-sm input"
                       pInputText placeholder="EOD Job Name">
              </div>
            </div>
            <div class="col-md-4 controlGap contentbox">
              <div class="fieldBox">
                <label class="label ng-star-inserted">Company</label>
                <p-dropdown formControlName="company"
                            [disabled]="false"
                            [id]="'company'"
                            [options]="companyList"
                            (onChange)="changecompany($event)"
                            appendTo="body"
                            tooltipEvent="focus" tooltipPosition="bottom"
                            tooltipZIndex="1000">
                </p-dropdown>
              </div>
            </div>
            <div class="col-md-4 controlGap contentbox">
              <div class="fieldBox">
                <label class="label ng-star-inserted">Trade Type</label>
                <ctrm-multiselect formControlName="tradeType"
                                  [allowSearch]="true"
                                  [id]="'tradeType'"
                                  [disabled]="tradetypedisable"
                                  [list]="tradeTypeList"
                                  [mclass]="'form-control form-control-sm'"
                                  [msClass]="'form-control-normal'"
                                  [multiple]="true"
                                  [outputFormat]="'string'"
                                  [placeholder]="'Select Trade Type'">
                </ctrm-multiselect>
              </div>
            </div>
            <div class="col-md-4 controlGap contentbox">
              <div class="fieldBox">
                <label class="label ng-star-inserted">EOD Currency</label>
<!--                <input formControlName="eodCurrency"-->
<!--                       [id]="'eodCurrency'"-->
<!--                       class="form-control form-control-sm pickerInput"-->
<!--                       placeholder="Currency"-->
<!--                       disabled-->
<!--                       type="text">-->
                <p-dropdown formControlName="eodCurrency"
                            [disabled]="companyNotAll"
                            [id]="'company'"
                            [options]="eodCurrencyList"
                            appendTo="body"
                            tooltipEvent="focus" tooltipPosition="bottom"
                            tooltipZIndex="1000">
                </p-dropdown>
              </div>
            </div>
            <div class="col-md-4 controlGap contentbox">
              <div class="fieldBox">
                <label class="label ng-star-inserted">EOD Uom</label>
                <p-dropdown formControlName="eodUom"
                            [disabled]="companyNotAll"
                            [id]="'company'"
                            [options]="eodUomList"
                            appendTo="body"
                            tooltipEvent="focus" tooltipPosition="bottom"
                            tooltipZIndex="1000"></p-dropdown>
              </div>
            </div>
            <div class="col-md-4 controlGap contentbox">
              <div class="fieldBox">
                <label class="label ng-star-inserted">Status</label>
                <div>
                  <p-selectButton [options]="statusValues"
                                  [formControl]="eodFormGroup.controls['status']"
                                  tooltipEvent="focus" tooltipPosition="bottom"
                                  tooltipZIndex="1000">
                    <ng-template let-item>
                      <div class="optionButton">
                        <i [ngClass]="eodFormGroup.controls['status'].value === item.value?'fas fa-check-circle':'far fa-circle'"></i>
                        <span>{{item.label}}</span>
                      </div>
                    </ng-template>
                  </p-selectButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sectionBoxMain">
          <div style="display: flex; padding-bottom: 20px">
            <div class="formSectionTitle" style="width: 16%">
              <label class="label ng-star-inserted">Schedule parameters</label>
            </div>
            <div class="formSectionTitle" style="width: 84%">
              <p-inputSwitch (onChange)="switchValue($event)"></p-inputSwitch>
            </div>
          </div>
          <div [hidden]="!disable">
            <div style="display: flex; padding-top: 2%">
              <div style="padding-right: 3%; width: 28%;">
                <label class="label ng-star-inserted">Value Date</label>
                <input type="text"
                       placeholder="Select Date"
                       class="form-control"
                       [bsConfig]="{useUtc:true}"
                       bsDatepicker
                       style="height: 38px;">
              </div>
              <div style="padding-right: 3%; width: 28%;">
                <label class="label ng-star-inserted">Pre-Requiste</label>
                <ctrm-multiselect formControlName="tradeType"
                                  [allowSearch]="true"
                                  [id]="'tradeType'"
                                  [disabled]="false"
                                  [list]="tradeTypeList"
                                  [mclass]="'form-control form-control-sm'"
                                  [msClass]="'form-control-normal'"
                                  [multiple]="true"
                                  [outputFormat]="'string'"
                                  [placeholder]="'Select Trade Type'">
                </ctrm-multiselect>
              </div>
            </div>
            <div style="padding-top: 3%;">
              <label class="label ng-star-inserted">Execution Internal</label>
              <div style="display: flex;">
                <div class="p-grid" style="width:100%;margin-bottom:10px">
                  <div style="display: flex;">
                    <div class="p-col-12">
                      <p-radioButton value="Daily" label="Daily" class="label ng-star-inserted"></p-radioButton>
                    </div>
                    <div style="padding-left: 50px; width: 50%">
                      <div class="p-col-12">
                        <p-radioButton value="Weekly" label="Weekly" class="label ng-star-inserted"></p-radioButton>
                      </div>
                      <div style="padding-left: 30px; padding-top: 5%;">
                        <div style="display: flex; padding-right: 5%;">
                          <p-checkbox label="Monday"></p-checkbox>
                          <p-checkbox label="Tuesday"></p-checkbox>
                          <p-checkbox label="Wednesday"></p-checkbox>
                        </div>
                        <div style="padding-top: 5%;">
                          <p-checkbox label="Thursday"></p-checkbox>
                          <p-checkbox label="Friday"></p-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="p-col-12">
                      <p-radioButton value="Monthly" label="Monthly" class="label ng-star-inserted"></p-radioButton>
                      <div style="padding-top: 10%;">
                        <ngx-number-spinner
                                [min]="1"
                                [max]="12">
                        </ngx-number-spinner>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label class="label ng-star-inserted">Execution Period</label>
              <div style="display: flex;">
                <ngx-timepicker-field
                        tooltipEvent="focus" tooltipPosition="bottom"
                        tooltipZIndex="1000"
                        formControlName="value" [controlOnly]="true"
                        class="timepicker"></ngx-timepicker-field>
                <label class="label ng-star-inserted" style="padding-left: 2%; padding-right: 2%;">to</label>
                <ngx-timepicker-field
                        tooltipEvent="focus" tooltipPosition="bottom"
                        tooltipZIndex="1000"
                        formControlName="value" [controlOnly]="true"
                        class="timepicker"></ngx-timepicker-field>
              </div>
            </div>

          </div>

        </div>
      </div>
    </form>
</ng-template>
