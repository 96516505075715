import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from "../../../../services/common.service";
import {environment} from "../../../../../environments/environment";
import {MessageService} from 'primeng';
import * as XLSX from "xlsx";
import * as fs from "file-saver";
import {Tcolumn} from "../../../../grid/tcolumn.model";

@Component({
  selector: 'app-history-expand',
  templateUrl: './history-expand.component.html',
  styleUrls: ['./history-expand.component.css']
})
export class HistoryExpandComponent implements OnInit {
  @Input() value: any[] = [];
  @Input() columns: any[] = [];
  @Input() eodJobName:any;
  @Input() icon: boolean = false;
  @Input() showChkBox: boolean = true;
  @Input() selectedRows: any[] = [];
  @Output() onSelectedRows = new EventEmitter();
  @Output() onAttachmentClick = new EventEmitter();
  @Output() onClickUniqueKey = new EventEmitter();
  @Output() onClickClickable = new EventEmitter();
  @Input() clickableFields: any[] = [];
  @Input() columnMinWidth: string = '150px';
  @Input() primaryKeyClickable: boolean = true;
  docUrl: any = '';

  constructor(public commonService:CommonService, private messageService: MessageService) {
  }

  ngOnInit(): void {
  }

  getStyleIfPossible(col: any) {
    let styleOBJ = {};
    if (col['style']) {
      styleOBJ = col['style'];
      return styleOBJ;
    } else {
      return {};
    }
  }

  isNotLast(index: number) {
    return (index < Object.keys(this.columns).length - 1);
  }

  getFormattedData(col: any, data: any) {
    if (data[col['field']] !== undefined && data[col['field']] !== null) {
      if (col['type'] === 'date') {
        return this.getDate(data[col['field']]);
      } else if (col['type'] === 'time') {
        return this.getTime(data[col['field']]);
      } else if(col['type'] === 'duration') {
        return this.getDuration(data['startDateTime'],data['endDateTime'])
      } else {
        return data[col['field']];
      }
    }
  }

  getDuration(timeStart:string,timeEnd:string) {
    let startDate = new Date(timeStart);
    let endDate = new Date(timeEnd);
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;
    return this.commonService.getDurationString(diff);
  }

  getDate(date) {
    let options = {day: 'numeric', month: 'long', year: 'numeric'};
    let newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", options);
  }

  getTime(time) {
    return this.commonService.getFormattedDate(new Date(time),"hh:mm:ss a");
  }

  downloadValidationResult(rowData) {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url+"/api/eodTemplate/v1/getValidationReport?eodJobName="+this.eodJobName+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next:any[]) => {
      _this.exportFile(next);

    });
  }

  private exportFile(rowData:any[]) {
    let sheets = {};
    let _this = this;
    let data = this.replaceKeyWithName(rowData);
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    sheets[this.eodJobName] = worksheet;
    let workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: Object.keys(sheets),
      Props: {Author: 'Taomish India Pvt. Ltd.'}
    };
    let excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer,this.eodJobName,'xlsx')
    this.showToast("Downloading Excel...", 'info')
  }

  public saveAsExcelFile(buffer: any, fileName: string, extension, middleFormat: string = '_validationReport_'): void {
    const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8'});
    fs.saveAs(data, fileName + middleFormat + new Date().getTime() + '.' + extension);
  }

  private replaceKeyWithName(data: any[] = []) {
    let newRowData = [];
    let _this = this;
    data.forEach(function (rowData) {
      let obj = {
        "EOD Job Name":_this.eodJobName,
        "Trade Id":rowData['tradeId'],
        "Obligation Id":rowData['obligationId'],
        "Trade Type":rowData['tradeType'],
        "Transaction Type":rowData['tradeTransactionType'] === "BUY" ? "BUY" : "SELL",
        "Company":rowData['company'],
        "Commodity":rowData['commodity'],
        "Origin":rowData['origin'],
        "Brand":rowData['brand'],
        "Grade":rowData['grade'],
        "Season":rowData['season'],
        "Incoterm":rowData['incoterm'],
        "Price Type":rowData['priceType'],
        "Location":rowData['location'],
        "Load Location":rowData['tradeLoadLocation'],
        "Discharge Location":rowData['tradeDischargeLocation'],
        "Error Message":rowData['errorMessage']
      };
      newRowData.push(obj);
    });
    return newRowData;
  }


  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }
}
